@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  
}

@layer components {
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
  .inbox-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
}

/* third-party libraries CSS */

/* .tableCheckbox:checked ~ div span {
  @apply opacity-100;
} */
/* .tableCheckbox:checked ~ div {
  @apply bg-primary border-primary;
} */

/* .apexcharts-legend-text {
  @apply !text-body dark:!text-bodydark;
} */

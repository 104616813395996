.coursesDetails {
  .itemOverview {
    .react-tabs__tab-list {
      border: none;
      margin: 0;
      padding: 0;
    }

    .react-tabs__tab {
      border: 1px solid #e9ecef !important;
      border-radius: 5px;
      padding: 16px 40px;
      color: #77838f;
      margin-right: 20px;
      border-radius: 5px !important;
      @media #{$lg-device} {
        margin-top: 10px;
      }
      @media #{$md-device} {
        margin-top: 10px;
      }
      @media #{$sm-device} {
        margin-top: 10px;
        padding: 12px 20px;
      }
    }

    .react-tabs__tab--selected {
      background: #f16126;
      border-radius: 5px;
      padding: 16px 40px;
      color: #fff;
      @media #{$sm-device} {
        margin-top: 10px;
        padding: 12px 20px;
      }
    }
    .react-tabs__tab-panel {
      margin-top: 50px;
    }
  }
  .accordion__button {
    border-radius: 5px !important;
  }
}

// Variable declarations

:root {
  // Color scheme
  --primary_color: #002147;
  --theme_color: #f16126;
  --heading_color: #000000;
  --text_color: #77838f;
  --bg_color: #f7f9fb;
  --border_color: #e9ecef;

  //Social icon colors
  --facebook: #3b5997;
  --twitter: #1ba1f2;
  --youtube: #ed4141;
  --linkedin: #0077b5;
  --pinterest: #e60022;
  --instagram: #c231a1;
  --vimeo: #00adef;
  --twitch: #6441a3;
}
// Media queries

$xl-device: "only screen and (min-width: 1280px)";
$lg-device: "only screen and (min-width: 1024px) and (max-width: 1279px)";
$md-device: "only screen and (min-width: 768px) and (max-width: 1023px)";
$sm-device: "only screen and (max-width: 767px)";

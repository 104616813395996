.studentsSays {
  .slick-dots {
    bottom: -40px !important;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #f16126;
  }
  .slick-dots li button:before {
    color: #f16126;
  }
}

.blogSection {
  .itemContainer {
    &:hover {
      .img__file img {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
      }
    }
  }
}

.map {
  background: url("~/public/assets/Images/bg_img/map.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 120px 0;
  &::before {
    background-color: #002147;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    opacity: 0.5;
  }
}
